import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from 'react-transition-group';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = 'pk.eyJ1IjoicmFkaWFsd29ya3MiLCJhIjoiY2oxY3RyeG42MDA3MzJ3cGhuNXJ6am5mYyJ9.Wjdq4kDq6EfVOwmuABL0aw'

function Contact(props) {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-79.836218);
    const [lat, setLat] = useState(43.5123845);
    const [zoom, setZoom] = useState(4.9);

    const [loaded, setLoaded] = useState(false);
    const isDevelopment = true;

    useEffect(() => {

        document.title = "Contact Belwood Consulting | Aluminum Consulting in Canada and the US"
        document.querySelector('meta[name="description"]').setAttribute("content", "Contact Belwood Consulting at info@belwoodconsulting.com. We provide industry leading aluminum Consulting in Canada and the US.");

        document.documentElement.scrollTop = 0;
        setLoaded(false);
        const hostName = window.location.hostname;
        if (!isDevelopment) {
          if (hostName === 'localhost') {
            var url = "http://localhost:3000";
          } else {
            var url = "";
          }

          axios({
            method: 'get',
            url: url + "/assets/data.json"
            })
            .then(response => {
           
              
            });
        } else {
      
        }
        

        if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/radialworks/ckx6v5sah26v815qwbzcq0dgv',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false,
            scrollZoom: false,
            interactive: false
            });
        
        props.menuColor("none");
        props.footerColor("blue");
        // map.current.scrollZoom.disable();

        

        setLoaded(true);
         map.current.on('load', function(){

         const markerInner = '<svg viewBox="0 0 100 100" expanded="true" width="280px" height="280px" xmlns="http://www.w3.org/2000/svg" class="cta on"><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.5)" class="pulse2"></circle><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.5)" class="pulse"></circle><circle cx="50%" cy="50%" r="25%" fill="rgba(253,221,0,0.3)" ></circle> <circle cx="50%" cy="50%" r="25%" fill="none" stroke="rgba(238,54,38,1)" strokeWidth="3"></circle></svg>';

          var markerContainer = document.createElement('div');
          markerContainer.className = "marker";
          markerContainer.innerHTML = markerInner;
          const marker = new mapboxgl.Marker({
            element: markerContainer,
            offset: [15, -5],
            anchor: "top"
          })
          .setLngLat([-79.836218,43.5123845])
          .addTo(map.current);

          
        });
    }, []);




  return (
    <div className="page blue">
        
            <CSSTransition in={loaded} timeout={200} classNames="pageItem">
                <div className="content">
                  <div ref={mapContainer} className="map-container" />
                  <div className="row">
                    <div className="column" id="contact">
                        <h2>CONTACT</h2>

                        <div className="contactGrid">
                        <span>
                        <h3>Get started with a conversation.</h3>
                        <p>Learn more about how we can help your business.</p>
                        </span>
                        <span>
                            <span className="contactInfo"><h4>EMAIL</h4><a href="mailto:info@radialworks.com">info@belwoodconsulting.com</a></span>
                            <span className="contactInfo"><h4>PHONE</h4> <a href="tel:+19179461874">905.301.1255</a></span>
                            <span className="contactInfo"><h4>OFFICE</h4> <p>1019 Freeman Trail<br/>
                            Milton Ontario L9T5T3</p></span>
                            
                        </span>
                      </div>

                     
                    </div>
                  </div>
                      
                </div>
            </CSSTransition>
     
        
    </div>
  );
}

export default Contact;



