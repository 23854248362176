import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { getStatic } from '../static';
import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import Gears from './gears';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/mousewheel/mousewheel.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';

import axios from 'axios';
import dataJSON from '../static/data.json';
// import rightArrow from '../static/assets/svg/arrow-right.svg' 

function Engage(props) {

  let {engageSlug} = useParams();
  

  // const history = useHistory();
  const isDevelopment = true;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [pageReady, setPageReady] = useState(false);
  const [clients, setClients] = useState([]);
  const [detail, setDetail] = useState(null);
  const [processes, setProcesses] = useState([]);
  const [services, setServices] = useState([]);
  const [slugName, setSlugName] = useState(null);
  const [swiperDom, setSwiperDom] = useState(null);
  const [menuLoad, setMenuLoad] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const hostName = window.location.hostname;
  
  SwiperCore.use([Navigation, Pagination, Mousewheel, FreeMode]);

  function loadContent(slugName) {

    // console.log('load content', slugName);

    // var slugName = slug;
      if (!isDevelopment) {
        if (hostName === 'localhost') {
          var url = "http://localhost:3000";
        } else {
          var url = "";
        }

        axios({
          method: 'get',
          url: url + "/assets/data.json"
          })
          .then(response => {
            const dataJSON = response.data;
            // console.log('dataJSON', dataJSON, slugName);
            dataJSON.services.filter(item => item.slug === slugName).map(result => (
              setDetail(result)
            ));
            setClients(dataJSON.clients);
            setServices(dataJSON.services);
          });

      } else {

          dataJSON.services.filter(item => item.slug === slugName).map(result => (
            setDetail(result)
          ));
          setClients(dataJSON.clients);
          setServices(dataJSON.services);  

      }
  }

  function calcPos(item) {
    var width = document.documentElement.clientWidth;
    var pos = {}
    if (width > 600) {
     pos['transform'] = "translateX(" + item.position[0] + ") translateY(" + item.position[1] + ")";
    } else {
      pos['transform'] = "translateX(" + item.position_m[0] + ") translateY(" + item.position_m[1] + ")";
    }
    // console.log('pos', pos);
    return pos
  }

  useEffect(() => {
   
   

      if (engageSlug) {
        var slugName = engageSlug;
        setMenuLoad(false);
      } else {
        var slugName = 'build-an-app';
        window.history.pushState({}, undefined, "/engage/build-an-app");
        setActiveSlide(0);
        setMenuLoad(true);
      }
    setSlugName(slugName);
    props.menuColor("royalBlue");
    props.footerColor("auto");

    

  }, []);


  useEffect(() => {
    setPageLoaded(false);
    setPageReady(false);
    if (slugName) {
      props.footerColor("auto");
      setProcesses([]);
      setDetail(null);
      loadContent(slugName);
      console.log('slugName', slugName)
    }  
    
  }, [slugName]);

  useEffect(() => {
    // console.log('slug 0', engageSlug)
    setPageLoaded(false);
    setPageReady(false);
    if (engageSlug) {
      setSlugName(engageSlug);
    } else {
      var newSlugName = 'build-an-app';
     
      if (slugName) {
        window.history.pushState({}, undefined, "/engage/build-an-app/");
        setActiveSlide(0);
        setMenuLoad(true);
        if (slugName === newSlugName) {
          setPageLoaded(true);
          setPageReady(true);
        } else {
          setSlugName(newSlugName);
        }
      } else {
        window.history.pushState({}, undefined, "/engage/build-an-app/");
        setActiveSlide(0);
        setMenuLoad(true);
        setSlugName(newSlugName);
      }

    }
  }, [engageSlug]);

  useEffect(() => {
        if (detail) {
          document.documentElement.scrollTop = 0;
          setProcesses(detail.processes)
          setPageLoaded(true);
          document.title = detail.title;
          document.querySelector('meta[name="description"]').setAttribute("content", detail.description);
        }
  }, [detail]);

  useEffect(() => {
    if ((services.length > 0)&&(swiperDom)&&(menuLoad !== null)) {
      var index = services.findIndex(obj => obj.slug === slugName);
      setActiveSlide(index+1);
      if (menuLoad === false) {
        swiperDom.slideTo(index+1, 0);
      } 
      if (menuLoad === true){
        setMenuLoad(false);
        swiperDom.slideTo(0, 0);
      }
      
    }
  }, [services, swiperDom])


  useEffect(() => {
    let timeline0 = gsap.timeline();
    if (pageLoaded === true) {
     
      setTimeout(() => {
        var width = window.screen.width;
        if (width > 600) {
          timeline0.fromTo("#serviceImage_0", {x: 0, y: 0, opacity: 0, scale: 1.0}, {x: 0, y: 0, opacity: 1, scale: 1.05, duration: 0.7, delay: 0, ease: "power0.easeNone"});
          timeline0.fromTo("#serviceImage_1", {opacity: 0, scale: 1.0}, {opacity: 1, scale: 1.05, duration: 0.75, delay: 0, ease: "power0.easeNone"});
          timeline0.fromTo("#serviceImage_2", {opacity: 0, scale: 1.0}, {opacity: 1, scale: 1.05, duration: 0.75, delay: 0, ease: "power0.easeNone"});
        } else {
          timeline0.fromTo("#serviceImage_0", {x: 0, y: 0,opacity: 0, scale: 1.0}, {x: 0, y: 0,opacity: 1, scale: 1, duration: 0.7, delay: 0, ease: "power0.easeNone"});
          timeline0.fromTo("#serviceImage_1", {opacity: 0, scale: 1.0}, {opacity: 1, scale: 1, duration: 0.75, delay: 0, ease: "power0.easeNone"});
          timeline0.fromTo("#serviceImage_2", {opacity: 0, scale: 1.0}, {opacity: 1, scale: 1, duration: 0.75, delay: 0, ease: "power0.easeNone"});
        }
        
        if (detail.slug === "team-augmentation") {
          let t2 = gsap.timeline();
          t2.fromTo("#piece1", {scale: 1.8, x: 2, y: 8, rotate: 10}, {scale: 1, rotate: 0, x: 0, y: 0, duration: 0.8})
          t2.fromTo("#piece2", {scale: 1.4, x: -2, y: 0, rotate: -15}, {scale: 1, rotate: 0, x: 0, y: 0,  duration: 0.8}, "-=0.5")
          t2.fromTo("#piece3", {scale: 1.4, x: 0, y: 10, rotate: 5}, {scale: 1, rotate: 0, x: 0, y: 0,  duration: 0.8}, "-=0.75")
          t2.fromTo("#piece4", {scale: 1.8, x: 30, y: 10,otate: 0}, {scale: 1, rotate: 0, x: 0, y: 0,  duration: 0.8}, "-=1")
        }

        setPageReady(true);
        // console.log('props', props);
      }, 500);
      
    } else {
      timeline0.kill();
    }
  }, [pageLoaded])

  return (
    <div className="page royalBlue" id="engage">
        {((detail)&&(processes)&&(pageLoaded)) ? 
          <div className={"content" + (pageReady ? " pageReady":"")}>
          
              <nav className="sub">
              
                    <Swiper
                      spaceBetween={20}
                      slidesPerView={"auto"}
                      navigation
                      slideToClickedSlide={true}
                      onSwiper={(swiper) => setSwiperDom(swiper)}
                      className="swiper-slide engageSlider"
                      onActiveIndexChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                      initialSlide={activeSlide}
                      cssMode={(props.isMobile)?true:false}
                      freeMode={{
                        enabled: true,
                        sticky: true
                      }}
                      mousewheel={{
                        forceToAxis: true
                      }}
                      >
                      <SwiperSlide>
                        <span className="context">WAYS TO<br/>ENGAGE</span>
                      </SwiperSlide>
                      {services.map((service, i) => (
                        <SwiperSlide 
                          className={(service.slug === slugName)?"on":"auto"}
                          key={"service_" + i}
                        >
                          <Link to={service.url} aria-label={service.heading} onClick={() => props.footerColor("off")}>
                          <img src={getStatic(service.icon)} className="serviceIconSm" alt={service.heading} />
                          <span>{service.heading}</span>
                          </Link>
                        </SwiperSlide>
                      ))}
                      <SwiperSlide>
                        <span className="lastBtn">

                        </span>
                      </SwiperSlide>
                    </Swiper>
    
       
              </nav>
                  <CSSTransition in={pageLoaded} timeout={200} classNames="pageItem">
                  <div className="content">

                    <div className="row intros">
                        <div className="column" id="detail">
                          <div className="twoColumn">
                              <div className="detailColumn textColumn">
                                <div className="textColumnWrap">
                                  <h1>{detail.heading}</h1>
                                  <h2>{detail.subheading}</h2>
                                  <span dangerouslySetInnerHTML={ {__html: detail.text} } />
                                </div>
                              </div>
                              <div className="detailColumn imageColumn">
                                <div className="servicesImages">
                                {detail.media.map((item, i) => (
                                  (item && 
                                    
                                    <div
                                      className={"serviceImage " + item.format}
                                      key={"item_" + i}
                                      id={"serviceImage_" + i}
                                      aria-label={detail.heading + " image " + i} 
                                      >
                                      <div 
                                      className={"servicePos" + (((item.position[0]&&item.position[1]) !== "0") ? " servicePosAbsolute":"")}
                                      style={calcPos(item)}
                                      >
                                      {item.image && <img src={getStatic(item.image)} className="mediaImage" alt={detail.heading}/>}
                                      {item.video && 
                                      <video playsInline autoPlay muted loop>
                                        <source src={getStatic(item.video)} type="video/mp4" />
                                      </video>}
                                      {item.svg && 
                                        <SVG src={getStatic(item.svg)} />
                                      }
                                      {(item.format === "gears") &&
                                        <Gears />
                                      }
                                      </div>
                                    </div>)
                                  
                
                                ))}
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
       
                    <div className="row blue2" id="process">
                      <div className="column">
                        <h2>PROCESS</h2>
                      </div>
                      <div className="column">
                        {processes.map((item, i) => (
                          <div className="item" key={"item_" + i}>
                            <h3>{item.heading}</h3>
                            <h4>{item.subheading}</h4>
                            <p>{item.text}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="row swiper white" id="caseStudies">
                      <div className="column">
                        <h2>CASE STUDIES</h2>
                      </div>
                      <div className="swiperColumn">
                        <Swiper
                        // spaceBetween={80}
                        // slidesPerView={2.35}
                        breakpoints={{
                          0: {
                            slidesPerView: 1.2,
                            spaceBetween: 20
                          },
                          840: {
                            slidesPerView: 2.35,
                            spaceBetween: 80
                          },
                          1600: {
                            slidesPerView: 3.5,
                            spaceBetween: 80
                          },
                          2800: {
                            slidesPerView: 6,
                            spaceBetween: 80
                          }
                        }}
                        centerInsufficientSlides={true}
                        modules={[ Navigation, Pagination ]}
                        navigation
                        className="clients swiper-slide"
                        cssMode={(props.isMobile)?true:false}
                        freeMode={{
                          enabled: true,
                          sticky: true
                        }}
                        mousewheel={{
                          forceToAxis: true
                        }}
                        >
                        {clients.map((client, i) => (
                          <SwiperSlide id={"client_" + i} key={"client_" + i}>
                          <span>
                          {(client.url)?(<Link to={client.url} className="client" aria-label={client.heading} >
                            {(client.image)?(
                              
                              <img src={getStatic(client.image)} className="clientImage" alt={client.heading} />
                              ):""}
                              <div className="clientOverlay">
                              {(client.heading)?(<h3>{client.heading}</h3>):""}
                              </div>
                            
                              {(client.subheading)?(<p>{client.subheading}</p>):""}
                            
                        
                            </Link>
                            
                            ):(<span></span>)}
                          {(client.tags.length > 0)?<span className="tags">
                            {(client.tags.map((tag, i) => (
                              (services.filter(service => (service.id === tag)).map((fTag, j) => (
                                <Link 
                                  className="tag"
                                  to={fTag.url}
                                  key={"tag_" + j}
                                  aria-label={fTag.heading} 
                                  >
                                  {fTag.heading}
                                </Link>
                              )))
                            )))}
                            </span>:""}
                          </span>
                          </SwiperSlide>
                        ))}
                        <SwiperSlide className="swiper-slide lastSlide">
                        <span className="context lastBtn"></span>
                          </SwiperSlide>
                        </Swiper>      
                      </div>
                    </div>
                
                    <div className="row royalBlue" id="contact">
                    <div className="column">
                      <h2>CONTACT US</h2>
                      <div className="contactGrid">
                        <span>
                          <h3>Start with a project.</h3>
                          <p>Contact us about building something for your business.</p>
                        </span>
                        <span>
                            <span className="contactInfo"><h4>EMAIL</h4><a href="mailto:info@radialworks.com">info@radialworks.com</a></span>
                            <span className="contactInfo"><h4>PHONE</h4> <a href="tel:+19179461874">917.946.1874</a></span>
                        </span>
                      </div>
                    </div>
                  </div>

                    </div>
                    </CSSTransition>
                
                    </div>
           
            :
            ""
        }
        
    </div>
  );
}

export default Engage;



