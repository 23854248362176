import path from 'path'
import * as url from 'url'

const isDevelopment = true;
const productionStatic = "static/"
export function getStatic(val) {
  if (isDevelopment) {
    return url.resolve(window.location.origin, val)
  }
  return path.resolve(productionStatic, val)
}