import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  Routes,
  Route
} from "react-router-dom";
import { gsap } from "gsap";
import './App.css';
import Home from './components/home';
import Engage from './components/engage';
import Study from './components/study';
import SignIn from './components/signin';
import About from './components/about';
import Contact from './components/contact';
import Product from './components/product';

import { ReactComponent as Logo } from './static/assets/svg/Logo.svg';
import { ReactComponent as MenuBtn } from './static/assets/svg/menu.svg';

function App() {


  const [menuOn, setMenuOn] = useState(null);
  const [navSm, setNavSm] = useState(false);
  const [menuClass, setMenuClass] = useState('blue');
  const [yPos, setYPos] = useState(0);
  const [footerClass, setFooterClass] = useState('auto');
  const [pageChange, setPageChange] = useState(null);
  const [isMobile, setIsMobile] = useState(null);
  const [isTablet, setIsTablet] = useState(null);

  function handleMenuBtn(openMenu) {
    var b1 = document.getElementById('bar1');
    var b2 = document.getElementById('bar2');
    var b3 = document.getElementById('bar3');
    if (menuOn) {
      setMenuOn(false);
      b2.classList.remove('off');
      b1.setAttribute('d', 'M0,4 L32,4');
      b3.setAttribute('d', 'M0,28 L32,28');
      // console.log('yPos', yPos);
    } else {
      if (openMenu) {
        setMenuOn(true);
        b2.classList.add('off');
        b1.setAttribute('d', 'M1,1 L31,31');
        b3.setAttribute('d', 'M1,31 L31,1');
        var scrollY = window.scrollY;
        setYPos(scrollY);
        console.log(scrollY);
      }
    }
  }

  useEffect(() => {
    if ((!menuOn)&&(!pageChange)) {
      window.scrollTo(0, parseInt(yPos));
      setYPos(0);
    }
  }, [menuOn])

  useEffect(() => {
    if (pageChange) {
      handleMenuBtn(false);
      setYPos(0);
      setPageChange(false);
    }
  }, [pageChange])

  useEffect(() => {
    if (menuClass) {
      var b = document.getElementsByTagName('body');
      b[0].classList.remove(...b[0].classList);
      b[0].classList.add(menuClass);
    }
    
  }, [menuClass])

  useEffect(() => {
    
    document.title = "Belwood Consulting | Aluminum Consulting in Canada and the US"
    document.querySelector('meta[name="description"]').setAttribute("content", "Belwood Consulting provides industry leading aluminum Consulting in Canada and the US. Contact us at info@belwoodconsulting.com");

    gsap.timeline({
      scrollTrigger: {
        trigger: ".app",
        start: "top top",
        end: "4000px",
        scrub: 0,
        markers: false,
        onToggle: self => setNavSm(self.isActive)
      }
    });

    var width = document.documentElement.clientWidth;
    console.log('width')
    if (width > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);




  return (

      <div className={"app" + ((menuOn)?(" menuOn"):(""))}>
        <header className={"column " + menuClass + ((navSm)?(" navSm"):(""))} id="top">
          <Link aria-label="home" to="/" onClick={() => handleMenuBtn(false)}><Logo id="logo" className={menuClass} /></Link>
          <div className="menuWrap">
            <div id="signIn">
             {/* <Link to="/sign-in">Sign In</Link> */}
            </div>
            <Link to="/contact" className="link" onClick={() => setPageChange(true)}>CONTACT</Link>
          </div>
        </header>
        <section id="menu" className={menuClass}>

          <nav>
            <ul>
              <li><Link to="/about" onClick={() => setPageChange(true)}>About</Link></li>
              <li><Link to="/contact" onClick={() => setPageChange(true)}>Contact</Link></li>
            </ul>
           
          </nav>
        </section>
        <section id="core">
       
          <Routes>
            <Route path="/" element={<Home isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} />
            <Route path="contact" element={ <Contact isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} />
            <Route path="about" element={ <About isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} />
            <Route path="engage" element={ <Engage isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} >
              <Route path=":engageSlug" element={ <Engage isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} />
            </Route>
            <Route path="work" element={ <Study isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} >
              <Route path=":studySlug" element={ <Study isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass}/>} />
            </Route>
            <Route path="products" element={ <Product isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass} />} >
              <Route path=":productSlug" element={ <Product isMobile={isMobile} menuColor={setMenuClass} footerColor={setFooterClass}/>} />
            </Route>

          </Routes>
       
      
        </section>
        <footer className={footerClass}>
          <div className="column">
              <div className="row">
                <p>&copy; {new Date().getFullYear()} Belwood Consulting, Inc</p> 
                <p className="address">1019 Freeman Trail, Milton, Ontario<br/>
                <a href="mailto:info@belwoodconsulting.com">info@belwoodconsulting.com</a><br/>
                <a href="tel:+19055144611">905.514.4611</a></p>
              </div>
          </div>
        </footer>
      </div>

  );
}

export default App;

