import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';

import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/mousewheel/mousewheel.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';


import axios from 'axios';
import dataJSON from '../static/data.json';

function Study(props) {
  
  let {studySlug} = useParams();

  const isDevelopment = true;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [pageReady, setPageReady] = useState(false);
  const [clients, setClients] = useState([]);
  const [detail, setDetail] = useState(null);
  const [services, setServices] = useState([]);
  const [slugName, setSlugName] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiperDom, setSwiperDom] = useState(null);
  const [menuLoad, setMenuLoad] = useState(null);

  const hostName = window.location.hostname;
  
  function loadContent(slugName) {
      
    // var slugName = slug;
      if (!isDevelopment) {
        if (hostName === 'localhost') {
          var url = "http://localhost:3000";
        } else {
          var url = "";
        }

        axios({
          method: 'get',
          url: url + "/assets/data.json"
          })
          .then(response => {
            const dataJSON = response.data;
            console.log('dataJSON', dataJSON, slugName);
            dataJSON.clients.filter(item => item.slug === slugName).map(result => (
              setDetail(result)
            ));
            setClients(dataJSON.clients);
            setServices(dataJSON.services);


          });

      } else {
          dataJSON.clients.filter(item => item.slug === slugName).map(result => (
            setDetail(result)
          ));
          setClients(dataJSON.clients);
          setServices(dataJSON.services);
   
            
          
      }
  }
  useEffect(() => {
    setPageLoaded(false);
    document.documentElement.scrollTop = 0;
  

    props.menuColor("blue2");
    props.footerColor("auto");
  }, []);


  useEffect(() => {
    setPageLoaded(false);
    setPageReady(false);
    if (slugName) {
      props.footerColor("auto");
      loadContent(slugName);
      setSlugName(slugName);
    }  

  }, [slugName]);

  useEffect(() => {
    if (studySlug) {
      setSlugName(studySlug);
      setMenuLoad(false);
    }  else {
      var slugName = 'brookfield-properties-presentation-system';
      window.history.pushState({}, undefined, "/work/brookfield-properties-presentation-system/");
      setSlugName(slugName);
      setActiveSlide(0);
      setMenuLoad(true);
      
    }

  }, [studySlug]);


  useEffect(() => {
      if (detail) {
        setPageLoaded(true);
        setPageReady(true);
        document.title = detail.title;
        document.querySelector('meta[name="description"]').setAttribute("content", detail.description);
      }
  }, [detail]);

  useEffect(() => {
    if ((clients.length > 0)&&(swiperDom)&&(menuLoad !== null)) {
      var index = clients.findIndex(obj => obj.slug === studySlug);
      console.log('index', index, studySlug, clients);
      setActiveSlide(index+1);
      
      if (menuLoad === false) {
        swiperDom.slideTo(index+1, 0);
      } 
      if (menuLoad === true) {
        setMenuLoad(false);
        swiperDom.slideTo(0, 0);
      }
      
    }
  }, [clients, swiperDom, menuLoad])

  

  return (
    <div className="page blue2">
        {(detail) ? 
          <div className={"content" + (pageReady ? " pageReady":"")}>
  
              <nav className="sub clientSub">
              
                    <Swiper
                      spaceBetween={20}
                      slidesPerView={"auto"}
                      centerInsufficientSlides={false}
                      modules={[Navigation, Mousewheel, FreeMode]}
                      slideToClickedSlide={true}
                      onSwiper={(swiper) => setSwiperDom(swiper)}
                      navigation
                      className="swiper-slide"
                      onActiveIndexChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                      initialSlide={activeSlide}
                      cssMode={(props.isMobile)?true:false}
                      freeMode={{
                        enabled: true,
                        sticky: true
                      }}
                      mousewheel={{
                        forceToAxis: true
                      }}
                      >
                      <SwiperSlide>
                        <span className="context">OUR<br/>WORK
                        </span>
                      </SwiperSlide>
                      {clients.map((item, i) => (
                        <SwiperSlide 
                          className={(item.slug === slugName)?"on":"auto"}
                          key={"service_" + i}
                          onClick={() => props.footerColor("off")}
                        >
                          <Link to={item.url}>
                            <span className="clientName">{item.heading}</span>
                            <span className="clientCategory">{item.category}</span>
                          </Link>
                        </SwiperSlide>
                      ))}
                      <SwiperSlide>
                      <span className="context lastBtn"></span>
                    </SwiperSlide>

                    </Swiper>
    
       
              </nav>

                  <CSSTransition in={pageLoaded} timeout={200} classNames="pageItem">
                  <div className="content" id="caseStudy">
                    
                  <div className="column">
                    <div className="row shortIntro blue2" id="detail">
                        <div className="column0">
                            <div className="">
                              <h1>{detail.heading}</h1>
                              <h2>{detail.subheading}</h2>
                              <span dangerouslySetInnerHTML={ {__html: detail.text} } />
                            </div>

                            {(detail.tags.length > 0)?<span className="tags">
                            {(detail.tags.map((tag, i) => (
                              (services.filter(service => (service.id === tag)).map((fTag, j) => (
                                <Link 
                                  className="tag"
                                  to={fTag.url}
                                  key={"tag_" + j}
                                  >
                                  {fTag.heading}
                                </Link>
                              )))
                            )))}
                            </span>:""}
                    
                        </div>
                        <div className="column0">
                            <div className="detailItem">
                              <h3>Client</h3>
                              <p>{detail.client}</p>
                            </div>
                            <div className="detailItem">
                              <h3>Location</h3>
                              <p>{detail.location}</p>
                            </div>
                            {detail.designer && <div className="detailItem">
                            <h3>Partner</h3>
                            <p>{detail.designer}</p>
                          </div>}
                            
                    
                        </div>
                    </div>
                    </div>

                  <div className="row lightBlue studyPages">
                  
                  <span className="gridInner">
                  <figure className={"studyPage studyPage-first"}>
                  </figure>
                    {(detail.pages) ? (detail.pages.map((item, i) => (
                    <figure className={"studyPage studyPage-" + i + " " + item.format} key={"figure_" + i}>
                    {(item.image) && <img src={getStatic(item.image)} style={{'objectPosition': item.objectPosition}} alt={detail.heading + " image" + i} />}
                    {item.video && 
                      <video playsInline autoPlay muted loop style={{'objectPosition': item.objectPosition}}>
                        <source src={getStatic(item.video)} type="video/mp4" />
                      </video>}


                    </figure>
                    ))) : null}
                    <figure className={"studyPage studyPage-last"}>
                    </figure>
                    </span>
                  </div>
                
                  <div className="row royalBlue" id="contact">
                  <div className="column">
                    <h2>CONTACT US</h2>
                    <div className="contactGrid">
                      <span>
                        <h3>Start with a project.</h3>
                        <p>Contact us about building something for your business.</p>
                      </span>
                      <span>
                          <span className="contactInfo"><h4>EMAIL</h4><a href="mailto:info@radialworks.com">info@radialworks.com</a></span>
                          <span className="contactInfo"><h4>PHONE</h4> <a href="tel:+19179461874">917.946.1874</a></span>
                      </span>
                    </div>
                  </div>
                </div>

                    </div>
                    </CSSTransition>
                
                    </div>
           
            :
            ""
        }
        
    </div>
  );
}

export default Study;



