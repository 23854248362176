import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getStatic } from '../static';
import SVG, { Props as SVGProps } from 'react-inlinesvg'

import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/mousewheel/mousewheel.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';

import axios from 'axios';
import dataJSON from '../static/data.json';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Home(props) {

    const isDevelopment = true;
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    const [pageReady, setPageReady] = useState(false);
    const [engageSwiper, setEngageSwiper] = useState(null);
    const [clientSwiper, setClientSwiper] = useState(null);

    useEffect(() => {

      document.title = "Belwood Consulting | Aluminum Consulting in Canada and the US"
      document.querySelector('meta[name="description"]').setAttribute("content", "Belwood Consulting provides industry leading aluminum Consulting in Canada and the US. Contact us at info@belwoodconsulting.com");
    
        document.documentElement.scrollTop = 0;
        setLoaded(false);
        const hostName = window.location.hostname;
        if (!isDevelopment) {
          if (hostName === 'localhost') {
            var url = "http://localhost:3000";
          } else {
            var url = "";
          }

          axios({
            method: 'get',
            url: url + "/assets/data.json"
            })
            .then(response => {
              const dataJSON = response.data;
              setData(dataJSON);
            });
            
        } else {
          setData(dataJSON);
        }        
        props.menuColor("none");
        props.footerColor("auto");

        console.log('home props', props);

    }, []);


  useEffect(() => {

    if (data) {
        setLoaded(true);
        setPageReady(true);
        // engageSwiper.update();
        let homeTl = gsap.timeline({delay: 0.5});
        // homeTl.fromTo('#homeLogo', {opacity: 1}, {opacity: 0, duration: 0.5})
        homeTl.fromTo('#intro_0', {opacity: 0}, {opacity: 1, duration: 1})
        homeTl.to('#intro_0', {height: 'auto', duration: 0}, "<")
        homeTl.to('#logo', {opacity: 1, duration: 0.5}, "<")
        return () => {
          homeTl.kill();
        }
        
    }
  }, [data]);



  return (
    <div className="page" id="home">
        {(data) ? 
            <CSSTransition in={loaded} timeout={200} classNames="pageItem">
                <div className={"content"  + (pageReady ? " pageReady":"")}> 
                 <div id="anim0">
                    <div className="row intros">
            

                          <div className="intro">
                     
                           {data.intros.map((intro, i) => (
                            (i === 0) && 
                             (<span className="introBanner" key={"intro_" + i} id={"intro_" + i}>
                             <Link to={intro.path}>
                                <div className='bannerText'>
                                  <h1><span dangerouslySetInnerHTML={ {__html: intro.heading} } /></h1>
                                
                                  <h2>{intro.text}</h2>
                                  
                                  
                                </div>
                               </Link>
                              </span>)
                              ))}
                          </div>
                        

                      </div>
                     
                  </div>
                  <div className="gradient"></div>
                  <video playsInline className="video" autoPlay muted loop>
                  <source src={getStatic('assets/vid/aluminum-rolls.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                  <div className="outerRow" id="row1">
                    <div className="row swiper">
                        <div className="column">
                          <h2>CORE SERVICES</h2>
                        </div>
                        <div className="swiperColumn">
                       
                        <Swiper
                        modules={[Navigation, Mousewheel, FreeMode]}
                        init={false}
                        breakpoints={{
                          0: {
                            slidesPerView: 1.2,
                            spaceBetween: 0
                          },
                          820: {
                            slidesPerView: 2.3,
                            spaceBetween: 15
                          },
                          1100: {
                            slidesPerView: 3.35,
                            spaceBetween: 30
                          },
                          1600: {
                            slidesPerView: 3.35,
                            spaceBetween: 30
                          },
                          2400: {
                            slidesPerView: 5,
                            spaceBetween: 80
                          }
                        }}
                        navigation
                        cssMode={(props.isMobile)?true:false}
                        className="swiper-slide"
                        freeMode={{
                          enabled: true,
                          sticky: true
                        }}
                        mousewheel={{
                          forceToAxis: true
                        }}
                        onSwiper={(swiper) => setEngageSwiper(swiper)}
                        >
                        {data.services.map((service, i) => (
                          <SwiperSlide 
                            key={"service_" + i}
                          >
                            <div className={"service"}>
                              <span>
                                <img src={getStatic(service.icon)} className="serviceIcon" alt={service.heading} />
                                <div className="textRow">
                                  <h3>{service.heading}</h3>
                                  <h4>{service.subheading}</h4>
                                  <span dangerouslySetInnerHTML={ {__html: service.text} } />
                                </div>
                                </span>
                                <div className="learn" style={{display: "none"}}>
                                <span>Learn more</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                                </div>
                            </div>
                          </SwiperSlide>
                        ))}
                        <SwiperSlide className="swiper-slide lastSlide">
                        <span className="context lastBtn"></span>
                      </SwiperSlide>
           
                        </Swiper>   
                      
                      </div>
                    
                    </div>
                   
                    
                  </div>
                  <div className="pageBackground darkGradient">

                 
                    
                    
                  </div> 

                  <div className="row swiper white" id="homeCaseStudies">
                    <div className="column">
                      <h2>AREAS OF EXPERIENCE</h2>
                      <h3>The North American aluminum market continues to increase in multiple layers of complexity.  Unfortunately this complexity can result in being unprofitable based on high costs or inability to compete.</h3>

                      <div className="blocks">
                          <div className="block">
                              <h4>Supply</h4>
                              <p>Lack of supply based on an allocated market, lack of relationship with domestic or offshore supply.</p>
                          </div>
                          <div className="block">
                            <h4>Market Complexity</h4>
                            <p>The North American aluminum market continues to increase in multiple layers of complexity.  Unfortunately this complexity can result in being unprofitable based on high costs or inability to compete.</p>
                          </div>
                          <div className="block">
                            <h4>International Trade</h4>
                            <p>Strategy is dramatically different in the US vs Mexico/Canada.  We have the expertise to help navigate either geography.</p>
                        </div>
                        <div className="block">
                          <h4>Gov’t Regulations</h4>
                          <p>Retroactive duties based on not understanding new regulations.</p>
                          <p>Anti-dumping, countervailing duties.</p>
                        </div>
                        <div className="block">
                          <h4>Cost</h4>
                          <p>Missing the lowest cost/highest quality supplier.</p>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                  
                 

                  <div className="row darkGrey" id="contact">
                    <div className="column">
                 
                      <h2>CONTACT US</h2>
                      <div className="contactGrid">
                        <span>
                          <h3>Get started with a conversation.</h3>
                          <p>Learn more about how we can help your business.</p>
                        </span>
                        <span>
                            <span className="contactInfo"><h4>EMAIL</h4><a href="mailto:info@belwoodconsulting.com">info@belwoodconsulting.com</a></span>
                            <span className="contactInfo"><h4>PHONE</h4> <a href="tel:+19179461874">905.514.4611</a></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  

                 
                  
                </div>
            </CSSTransition>
            :
            ""
        }
        
    </div>
  );
}

export default Home;