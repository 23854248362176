import React, { useState, useEffect, useRef } from "react";
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { getStatic } from '../static';
import { gsap } from "gsap";

import '../static/gears.css'

function Gears(props) {

    useEffect(() => {
        setTimeout(() => {




            gsap.to("#gear0", {rotate: 360, duration: 3, ease: "none", transformOrigin: "center center", repeat: -1});
            gsap.to("#gear1", {rotate: -360, duration: 6, ease: "none", transformOrigin: "center center", repeat: -1});
            gsap.to("#gear2", {rotate: -360, duration: 6, ease: "none", transformOrigin: "center center", repeat: -1});
            gsap.to("#gear3", {rotate: -360, duration: 30, ease: "none", transformOrigin: "center center", repeat: -1});

        }, 1000);
      

    }, [])
    
    return (
        <div id="screen">
            <SVG src={getStatic("assets/svg/gearset.svg")} />
        </div>
        
    );
}

export default Gears;