import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { getStatic } from '../static';
import { gsap } from "gsap";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import axios from 'axios';
import dataJSON from '../static/data.json';

function About(props) {
  
  let {slug} = useParams();
  const isDevelopment = true;
  const [pageLoaded, setPageLoaded] = useState(false);
  const [clients, setClients] = useState([]);
  const [detail, setDetail] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [services, setServices] = useState([]);
  const [engage, setEngage] = useState(false);
  const [slugName, setSlugName] = useState(null);

  const hostName = window.location.hostname;
  
  
  function loadContent() {
      
    // var slugName = slug;
      if (!isDevelopment) {
        if (hostName === 'localhost') {
          var url = "http://localhost:3000";
        } else {
          var url = "";
        }

        axios({
          method: 'get',
          url: url + "/assets/data.json"
          })
          .then(response => {
            const dataJSON = response.data;
            console.log('dataJSON', dataJSON, slug);
            setDetail(dataJSON.about);
            setClients(dataJSON.clients);
            setServices(dataJSON.services);


          });

      } else {
            setDetail(dataJSON.about);
          setClients(dataJSON.clients);
          setServices(dataJSON.services);
   
        
          
      }
  }
  useEffect(() => {
    
    setPageLoaded(false);
    document.documentElement.scrollTop = 0;
    loadContent();
    props.menuColor("blue2");
    props.footerColor("auto");

  }, []);


  useEffect(() => {
        if (detail) {

          document.title = detail.title;
          document.querySelector('meta[name="description"]').setAttribute("content", detail.description);

          setPageLoaded(true);


          if (document.documentElement.clientWidth < 601) {
            var offset = 0;
          } else {
            var offset = 400;
          }
        
          let aboutPageTL = gsap.timeline({
            scrollTrigger: {
              trigger: ".processRow",
              pin: false,
              markers: false,
              start: "top center+=" + offset,
              scrub: false,
            }
          });

          aboutPageTL.fromTo("#process_0", {opacity: 0, scale: 1}, {opacity: 1, scale: 1, duration: 0.75, delay: 0, ease: "power0.easeNone"});
          aboutPageTL.fromTo("#process_1", {opacity: 0, scale: 1}, {opacity: 1, scale: 1, duration: 0.75, delay: 0, ease: "power0.easeNone"});
          aboutPageTL.fromTo("#process_2", {opacity: 0, scale: 1}, {opacity: 1, scale: 1, duration: 0.75, delay: 0, ease: "power0.easeNone"});
      
          return () => {
            aboutPageTL.kill();
            aboutPageTL.kill();
          }
        }
  }, [detail]);

  return (
    <div className="page blue2" id="about">
        {(detail) ? 
    
            

                <CSSTransition in={pageLoaded} timeout={200} classNames="pageItem">
                  <div className="content">

                    <div className="row blue2" id="aboutIntro">
                        <div className="column" id="detail">
                            <div className="aboutText">
                              <h1>{detail.heading}</h1>
                              <h2>{detail.subheading}</h2>
                              <span dangerouslySetInnerHTML={ {__html: detail.text} } />
                              <div className="bios">
                              {detail.bios && detail.bios.map((item, i) => (
                                <div className="bioRow" key={'bio_' + i}>
                                <span className="quote">
                                { props.isMobile && 
                                  <div className="bio">
                                  <img src={getStatic(item.image)} alt={item.name} />
                                  <h3>{item.name}</h3><p>{item.title}</p>
                                </div>
                                }
                                  <span dangerouslySetInnerHTML={ {__html: item.quote} } />
                                  
                                  </span>
                                  { !props.isMobile && 
                                    <div className="bio">
                                    <img src={getStatic(item.image)} alt={item.name} />
                                    <h3>{item.name}</h3><p>{item.title}</p>
                                  </div>
                                  }
                                  
                                </div>
                              ))}
                              </div>
                              
                            </div>

           
                    
                        </div>
                    </div>


                  <div className="row imageRow darkBlue">
              
                    <div className="processRow">

                      <div className="column">
                        <h2>PROCESS</h2>
                        <span className="processIntro" dangerouslySetInnerHTML={ {__html: detail.text2} } />
                        <div className="processes">
                          {detail.process && detail.process.map((item, i) => (
                            <div className="process" id={"process_" + i} key={"process_" + i}>
                              <h4>{item.heading}</h4>
                              <p><span dangerouslySetInnerHTML={ {__html: item.text} } /></p>
                            </div>
                          ))}
                          
                        </div>
                        <div className="aboutText" id="history">
                        <h2>HISTORY</h2>
                        <span dangerouslySetInnerHTML={ {__html: detail.text3} } />
                      </div>
                      </div>
        
                    </div>
                    {(document.documentElement.clientWidth < 601) ? <img src={detail.image_m && getStatic(detail.image_m)} alt="" /> : <img src={detail.image && getStatic(detail.image)} alt="about radial works history" /> }
                    <div className="blue2Gradient overlay"></div>
                  </div>
                
                  <div className="row royalBlue" id="contact">
                  <div className="column">
                    <h2>CONTACT US</h2>
                    <div className="contactGrid">
                      <span>
                        <h3>Start with a project.</h3>
                        <p>Contact us about building something for your business.</p>
                      </span>
                      <span>
                          <span className="contactInfo"><h4>EMAIL</h4><a href="mailto:info@radialworks.com">info@radialworks.com</a></span>
                          <span className="contactInfo"><h4>PHONE</h4> <a href="tel:+19179461874">917.946.1874</a></span>
                      </span>
                    </div>
                  </div>
                </div>
                
                    </div>
                    </CSSTransition>

   
           
            :
            ""
        }
        
    </div>
  );
}

export default About;



